<template>
  <loading v-if="pageLoading" />
  <div v-else>
    <div v-if="table.results.length" class="message is-dark">
      <div class="message-body has-padding-75">
        <apart>
          <p>Add a new client.</p>
          <button
            class="button is-dark is-outlined is-rounded"
            @click="addClient"
          >
            Add client
          </button>
        </apart>
      </div>
    </div>

    <data-filter
      v-if="showDataFilter"
      :filters="filters"
      :active-filters="activeFilters"
      @filtersChanged="filtersChanged"
    />

    <b-table
      :data="table.data"
      :mobile-cards="true"
      :loading="table.loading"
      backend-sorting
      hoverable
      striped
      class="has-vertical-align-middle"
    >
      <template slot="empty">
        <no-results
          :cta="{ label: 'Add client' }"
          icon="box-open"
          title="No clients"
          message="There are no clients to show."
          @click="addClient"
        />
      </template>

      <template slot-scope="props">
        <b-table-column custom-key="avatar" width="40">
          <avatar
            :name="props.row.name || props.row.email"
            :avatar="props.row.avatarUrl"
            :size="35"
          />
        </b-table-column>

        <b-table-column
          label="Name"
          field="name"
          width="1"
          class="has-white-space-nowrap"
          >{{ props.row.name || "--" }}</b-table-column
        >

        <b-table-column label="Email" field="email">
          <router-link
            :to="{ path: getUserPath(props.row) }"
            class="has-text-dark"
          >
            <u>{{ props.row.email }}</u>
          </router-link>
        </b-table-column>

        <b-table-column custom-key="disabled" width="1">
          <status-label
            :status="props.row.isDisabled ? 'disabled' : 'active'"
          />
        </b-table-column>

        <b-table-column custom-key="controls" width="40">
          <b-dropdown
            position="is-bottom-left"
            class="has-text-left"
            :mobile-modal="false"
          >
            <a slot="trigger" class="has-text-dark">
              <b-icon icon="cog" />
            </a>
            <b-dropdown-item
              v-if="!props.row.isDisabled"
              @click="manageSitesOfClient(props.row)"
              >Manage sites</b-dropdown-item
            >
            <b-dropdown-item
              v-if="!props.row.isDisabled"
              @click="confirmSendPasswordReset(props.row)"
              >Reset password</b-dropdown-item
            >
            <b-dropdown-item
              v-if="!props.row.isDisabled"
              @click="disableAccount(props.row)"
              >Disable account</b-dropdown-item
            >
            <b-dropdown-item
              v-if="props.row.isDisabled"
              @click="enableAccount(props.row)"
              >Enable account</b-dropdown-item
            >
          </b-dropdown>
        </b-table-column>
      </template>
    </b-table>

    <load-more
      v-if="!table.complete"
      :loading="table.loading"
      @loadMore="getData(true)"
    />
  </div>
</template>

<script>
import { collection, query, where } from "@firebase/firestore";
import { mapState } from "vuex";

export default {
  name: "ResellerClientsTable",
  mixins: [require("@mixins/table-with-filtering-and-sorting").default],
  props: {
    resellerId: {
      type: String,
      required: true
    },
    adminContext: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      pageLoading: true,
      table: {
        sortable: false
      },
      filters: [
        {
          key: "email",
          label: "Email",
          type: "string"
        },
        {
          key: "name",
          label: "Name",
          type: "string"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      sites: state => state.sites.observed
    })
  },
  methods: {
    getUserPath(user) {
      return this.adminContext
        ? `/admin/users/${user._id}`
        : `/reseller/clients/${user._id}`;
    },
    getFirestoreRef() {
      let ref = collection(this.$firestore, "users");
      ref = query(ref, where("resellerId", "==", this.resellerId));
      return this.buildFirestoreRef(ref);
    },

    afterDataLoad() {
      this.pageLoading = false;
    },
    confirmSendPasswordReset(user) {
      const confirm = this.$confirm.open({
        parent: this,
        props: {
          autoClose: false,
          action: "Send password reset instructions",
          message: `Are you sure you want to send password reset instructions to this user?`
        },
        canCancel: ["outside"],
        events: {
          confirmed: async () => {
            await this.generateNewPassword(user).then(() => {
              confirm.close();
            });
          }
        }
      });
    },
    generateNewPassword(user) {
      return this.$store
        .dispatch("reseller/generateResetHash", { userId: user._id })
        .then(result => {
          this.$toast.open({
            message: result.message,
            type: "is-success",
            position: "is-bottom",
            duration: 6000
          });
        })
        .catch(error => {
          this.$toast.open({
            message: error.message,
            type: "is-danger",
            position: "is-bottom"
          });
        });
    },
    addClient() {
      const modal = this.$modal.open({
        component: () => import("@shared/reseller/_addResellerClientModal"),
        parent: this,
        width: 540,
        hasModalCard: true,
        canCancel: [],
        props: {
          parent: this,
          resellerId: this.resellerId
        },
        events: {
          created: () => {
            modal.close();
            this.getData();
          }
        }
      });
    },
    manageSitesOfClient(user) {
      this.$modal.open({
        component: () => import("@shared/reseller/_manageSitesOfClientModal"),
        parent: this,
        width: 540,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          user,
          userId: user._id,
          resellerId: this.resellerId
        }
      });
    },
    enableAccount(user) {
      this.table.loading = true;
      this.confirmedEnable({ user, enable: true }).then(() => {
        this.getData();
      });
    },
    disableAccount(user) {
      const confirm = this.$confirm.open({
        parent: this,
        props: {
          autoClose: false,
          action: "Disable account",
          message: `Are you sure you want to disable this account ?`
        },
        canCancel: ["outside"],
        events: {
          confirmed: async () => {
            await this.confirmedEnable({ user, enable: false }).then(() => {
              this.getData();
              confirm.close();
            });
          }
        }
      });
    },
    confirmedEnable({ user, enable }) {
      return this.$store
        .dispatch("user/enable", { userId: user._id, enable })
        .then(result => {
          this.$toast.open({
            message: result.message,
            type: "is-success",
            position: "is-bottom",
            duration: 6000
          });
        })
        .catch(error => {
          this.$toast.open({
            message: error.message,
            type: "is-danger",
            position: "is-bottom"
          });
        });
    }
  }
};
</script>
